.project {
  width: 80%;
  min-width: 300px;
  border-radius: 10px;
  margin-top: 50px;
  background: #fcf3ca;
}

.project-title {
  margin-left: 20px;
}

.project:last-of-type {
  margin-bottom: 50px;
}

.project-img {
  width: 100%;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
}

.project-imgs {
  border: 1px solid darkgray;
  margin-bottom: 1rem;
}

.project-description {
  padding: 0 20px;
  margin: 20px 0 0 0;
}

.project-links-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.project-link-item {
  margin-bottom: 1rem;
}

.project-links {
  display: flex;
  justify-content: space-evenly;
}

.project-link-name {
  margin: 20px 0 10px 0;
  font-weight: 600;
}
