.App {
  background: #fcf3ca;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 .5rem 0;
}

.fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
}

.logo {
  width: 150px;
  height: 150px;
  border: 3px solid #ef7b7b;
  border-radius: 50% 0 50% 50%;
  background: #c4eada;
  -moz-transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -webkit-transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -o-transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -ms-transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px) skewX(1deg)
    skewY(1deg);
}

.logo h1 {
  font-family: 'Lobster', cursive;
  font-size: 2.5rem;
  color: #37427f;
  text-align: center;
  -moz-transform: scale(1) rotate(40deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -webkit-transform: scale(1) rotate(40deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -o-transform: scale(1) rotate(40deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  -ms-transform: scale(1) rotate(40deg) translateX(0px) translateY(0px)
    skewX(1deg) skewY(1deg);
  transform: scale(1) rotate(40deg) translateX(0px) translateY(0px) skewX(1deg)
    skewY(1deg);
}

.projects-heading {
  font-size: 2rem;
}

.level-down {
  margin: 0 .5rem;
  font-size: 1.2rem;
  color: #37427f;
}

.right {
  background: #919190;
}

@media only screen and (min-width: 700px) {
  .App {
    display: flex;
  }

  .left {
    position: relative;
    align-items: center;
    width: 50%;
    height: 100vh;
    padding: 0;
  }

  .fixed {
    position: fixed;
  }

  .projects-heading {
    position: fixed;
    right: calc(50% - 130px);
    font-size: 3rem;
    transform: rotate(-90deg);
  }

  .level-down {
    margin: 0 1.1rem;
    font-size: 1.5rem;
  }

  .right {
    width: 50%;
  }
}
