@import url(https://fonts.googleapis.com/css?family=Lobster|Source+Code+Pro:400,500);
.skills {
  position: relative;
  display: flex;
}

.skills-left {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: right;
}

.skills-right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.skill {
  font-size: 1rem;
}

.split {
  position: absolute;
  top: 70px;
  left: 6px;
  width: 200px;
  height: 2px;
  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0)
  );
  transform: rotate(90deg);
}

/*
.platforms {
  display: flex;
  justify-content: center;
  font-size: 3rem;
} */

.platform-icon {
  align-self: flex-start;
  margin: 20px 10px 0 10px;
  font-size: 3rem;
  color: #37427f;
}

.platform-icon.align-right {
  align-self: flex-end;
}

.platform-icon:hover {
  color: #ef7b7b;
}

.project {
  width: 80%;
  min-width: 300px;
  border-radius: 10px;
  margin-top: 50px;
  background: #fcf3ca;
}

.project-title {
  margin-left: 20px;
}

.project:last-of-type {
  margin-bottom: 50px;
}

.project-img {
  width: 100%;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
}

.project-imgs {
  border: 1px solid darkgray;
  margin-bottom: 1rem;
}

.project-description {
  padding: 0 20px;
  margin: 20px 0 0 0;
}

.project-links-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.project-link-item {
  margin-bottom: 1rem;
}

.project-links {
  display: flex;
  justify-content: space-evenly;
}

.project-link-name {
  margin: 20px 0 10px 0;
  font-weight: 600;
}

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  background: #fcf3ca;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 .5rem 0;
}

.fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1.5rem;
}

.logo {
  width: 150px;
  height: 150px;
  border: 3px solid #ef7b7b;
  border-radius: 50% 0 50% 50%;
  background: #c4eada;
  transform: scale(1) rotate(-45deg) translateX(0px) translateY(0px) skewX(1deg)
    skewY(1deg);
}

.logo h1 {
  font-family: 'Lobster', cursive;
  font-size: 2.5rem;
  color: #37427f;
  text-align: center;
  transform: scale(1) rotate(40deg) translateX(0px) translateY(0px) skewX(1deg)
    skewY(1deg);
}

.projects-heading {
  font-size: 2rem;
}

.level-down {
  margin: 0 .5rem;
  font-size: 1.2rem;
  color: #37427f;
}

.right {
  background: #919190;
}

@media only screen and (min-width: 700px) {
  .App {
    display: flex;
  }

  .left {
    position: relative;
    align-items: center;
    width: 50%;
    height: 100vh;
    padding: 0;
  }

  .fixed {
    position: fixed;
  }

  .projects-heading {
    position: fixed;
    right: calc(50% - 130px);
    font-size: 3rem;
    transform: rotate(-90deg);
  }

  .level-down {
    margin: 0 1.1rem;
    font-size: 1.5rem;
  }

  .right {
    width: 50%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  font-family: 'Source Code Pro', monospace;
  font-weight: 500;
}

#root {
  height: 100%;
}

