.skills {
  position: relative;
  display: flex;
}

.skills-left {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: right;
}

.skills-right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.skill {
  font-size: 1rem;
}

.split {
  position: absolute;
  top: 70px;
  left: 6px;
  width: 200px;
  height: 2px;
  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0)
  );
  transform: rotate(90deg);
}

/*
.platforms {
  display: flex;
  justify-content: center;
  font-size: 3rem;
} */

.platform-icon {
  align-self: flex-start;
  margin: 20px 10px 0 10px;
  font-size: 3rem;
  color: #37427f;
}

.platform-icon.align-right {
  align-self: flex-end;
}

.platform-icon:hover {
  color: #ef7b7b;
}
